(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name wbInfo.directive:cardTypeInfo
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module='wbInfo'>
   <file name='index.html'>
   <card-type-info></card-type-info>
   </file>
   </example>
   *
   */
  angular
    .module('wbInfo')
    .directive('wbCardTypeInfo', wbCardTypeInfo);

  function wbCardTypeInfo() {
    return {
      restrict: 'E',
      templateUrl: 'wb-info/directives/wb-card-type-info-directive.tpl.html',
      replace: false,
      controllerAs: 'wbCardTypeInfo',
      controller: function (cardTypeLocal, cardTypeMap, $translate, _) {
        var vm = this
          , currentLang = $translate.use();

        vm.cardTypeMap = cardTypeMap;
        vm.cardTypes = cardTypeLocal[currentLang];

        vm.getCardTypesByCategory = function getCardTypesByCategory(category) {
          return _.filter(vm.cardTypes, function (cardType) {
            return cardType.category === category;
          });
        };
      }
    };
  }
}());
